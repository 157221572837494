import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import useQuery from '../../../../utils/useQuery'
import { companies, getIndustryTypes } from '../../../../constants/api'
import { CompanyType } from '../../../../stores/ADMarketTypes.types'
import useUserData from '../../../../stores/userData'
import useCampaignData from '../../../../stores/campaignData'
import useResponsive from '../../../../utils/useResponsive'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import {
	Button,
	ButtonGroup,
	Card,
	Form,
	InputGroup,
	Spinner,
} from 'react-bootstrap'
import { ScrollToError } from '../../../UtilityComponents/ScrollToFormikError'
import CommonFields from './CommonFields'
import ButtonGroupBooleanSwitch from '../../../UtilityComponents/ButtonGroupBooleanSwitch'
import FormLabelTooltip from '../../../UtilityComponents/FormLabelTooltip'
import { TOOLTIPS } from '../tooltips.data'
import SelfBusy from './SelfBusy'
import Company from './Company'
import yandexTracking from '../../../../utils/yandexTracking'
import { useConfirm } from '../../../UtilityComponents/ConfirmModalProvider'
import { authFetch } from 'src/utils/authFetch'
import { useHistory } from 'react-router-dom'
import s from './buisness.module.scss'
import { SelfBusyLimitCard } from './SelfBusyLimitCard'

const selfBusyLimit = 400_000

interface IBusinessDataForm {
	cn: any
	onSetCompanyId: ({ id, name }: { id: string; name: string }) => void
	onBack: () => void
}

const getPreviousFormData = (campaignId: string): BusinessFormType | null => {
	const savedData = window.sessionStorage.getItem(campaignId)

	if (savedData) {
		return JSON.parse(savedData)
	}

	return null
}
const commonFormKeys = [
	'legal_name',
	'name',
	'industry_type_id',
	'actual_location',
	'actual_location_lat',
	'actual_location_lon',
	'is_selfbusy',
	'inn',
]

const organizationFormKeys = ['kpp', 'legal_short_name']

export type IndustryTypes = { id: number; name: string }[]
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
export type BusinessFormType = Optional<
	CompanyType,
	'counts' | 'industry_type' | 'id'
> & {
	industry_type_id: number
	email: string
}

const BusinessDataForm: FC<IBusinessDataForm> = ({
	cn,
	onSetCompanyId,
	onBack,
}) => {
	const formDataRef = useRef<FormikProps<BusinessFormType>>(null)

	const [companiesList, isCompaniesLoading] = useQuery<CompanyType[]>({
		url: companies,
		method: 'GET',
	})

	const [industryTypes, isIndustryTypesLoading] = useQuery<IndustryTypes>({
		url: getIndustryTypes,
		method: 'GET',
	})
	const [isPreset, setIsPreset] = useState(true)
	const [user, userInterface] = useUserData()
	const [campaign, campaignInterface] = useCampaignData()
	const { confirm } = useConfirm()
	const history = useHistory()

	const [loading, setLoading] = useState(false)
	const isSelfBusyLimitReached =
		parseFloat(campaign.budget.total || '0') >= selfBusyLimit

	const previousFormData = getPreviousFormData(campaign.id)

	const [presetCompany, setPresetCompany] = useState<CompanyType | null>(
		() => {
			if (campaign?.company.is_draft) return null
			return campaign.company
		}
	)
	const { isMobile } = useResponsive()

	useEffect(() => {
		if (!presetCompany && companiesList) {
			setPresetCompany(companiesList[0])
		}
		if (companiesList?.length === 0) setIsPreset(false)
	}, [companiesList])

	useEffect(() => {
		return () => {
			if (formDataRef.current?.values) {
				const formValues = { ...formDataRef.current.values }

				const savedData = {}

				for (const key of commonFormKeys) {
					savedData[key] = formValues[key]
				}

				if (!formValues.is_selfbusy) {
					for (const key of organizationFormKeys) {
						savedData[key] = formValues[key]
					}
				}
				window.sessionStorage.setItem(
					campaign.id,
					JSON.stringify(savedData)
				)
			}
		}
	}, [])
	const initialValues = useMemo<BusinessFormType>(() => {
		if (previousFormData) {
			return {
				...previousFormData,
				email: user?.email,
			}
		}

		if (presetCompany)
			return {
				...presetCompany,
				industry_type_id: presetCompany.industry_type.id,
				email: user?.email,
			}
		return {
			name: '',
			industry_type_id: industryTypes?.[0].id || 0,
			legal_name: '',
			legal_short_name: '',
			actual_location: '',
			actual_location_lat: '',
			actual_location_lon: '',
			inn: '',
			kpp: '',
			email: user.email,
			is_selfbusy: false,
		}
	}, [industryTypes, presetCompany])

	const moveToModeration = async () => {
		await campaignInterface.moveCampaignToModeration(campaign.id)
		await yandexTracking.reachGoal('upload_to_moder')
		localStorage.removeItem(`stepo_${campaign.id}`)
		history.push('/campaigns/' + campaign.id)
	}

	async function onSubmit(
		values: BusinessFormType,
		utils: FormikHelpers<BusinessFormType>
	) {
		const answer = await confirm({
			text: 'После этого действия вы не сможете ее изменить',
			title: 'Вы уверены, что хотите отправить рекламную кампанию на модерацию?',
			closeButton: true,
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
		})
		if (!answer) return false

		const { setFieldError } = utils
		setLoading(true)
		///Ставим email, если он не указан и введен
		if (values?.email && values.email !== user.email) {
			try {
				await userInterface.setEmail(values.email)
			} catch (e: any) {
				setFieldError('email', e.data?.email?.[0])
				setLoading(false)
				return
			}
		}
		let formData = new FormData()
		;[
			'legal_name',
			'name',
			'industry_type_id',
			'actual_location',
			'actual_location_lat',
			'actual_location_lon',
			'is_selfbusy',
			'inn',
		].forEach((key) => {
			formData.append(key, values[key])
		})
		if (!values.is_selfbusy) {
			;['kpp', 'legal_short_name'].forEach((key) => {
				if (key === 'kpp') {
					if (values['kpp'] !== null) {
						formData.append(key, values[key])
					}
				} else {
					formData.append(key, values[key])
				}
			})
		}

		//creation campaign make DRAFT company(empty fields) if user haven't any companies(presets)
		if (!!campaign.company.is_draft) {
			formData.append('is_draft', 'false')
			//create company with form data and draft companie id
			await authFetch<any>({
				url: `${companies}${campaign?.company?.id}/`,
				method: 'PUT',
				body: formData,
			})
		} else {
			//using user preset
			if (isPreset) {
				await campaignInterface.patchCampaign(campaign?.id, {
					company_id: values.id,
				})
			} else {
				//create new one company
				const { id } = await authFetch<any>({
					url: companies,
					method: 'POST',
					body: formData,
				})

				await campaignInterface.patchCampaign(campaign?.id, {
					company_id: id,
				})
			}
		}
		await moveToModeration()
		setLoading(false)
	}

	function handleValidate(values) {
		values
		const errors: any = {}
		;['industry_type_id', 'name', 'email', 'inn', 'legal_name'].forEach(
			(name) => {
				if (name === 'email') {
					if (user?.email) return
				}
				if (!values[name]) {
					errors[name] = 'Обязательное поле'
				}

				if (name === 'inn') {
					if (
						values[name].length === 10 ||
						values[name].length === 12
					) {
						if (errors[name]) {
							delete errors[name]
						}
					} else {
						errors[name] = 'ИНН должен быть 10 или 12 цифр'
					}
				}
				if (values['is_selfbusy'] && !values['legal_name']) {
					errors['legal_name'] = 'Обязательное поле'
				} else {
					if (errors['legal_name']) {
						delete errors[name]
					}
				}
			}
		)
		;[
			'actual_location',
			'actual_location_lat',
			'actual_location_lon',
		].forEach((name) => {
			if (!values[name]) {
				errors.actual_location = 'Выберите адрес из выпадающего списка'
			}
		})

		return errors
	}

	return (
		<Formik
			innerRef={formDataRef}
			initialValues={initialValues}
			enableReinitialize
			onSubmit={onSubmit}
			validate={handleValidate}
			validateOnChange={false}
		>
			{({
				setFieldValue,
				values,
				handleSubmit,
				errors,
				setFieldError,
				resetForm,
			}) => {
				return (
					<div className={s.buisnessContainer}>
						<Card className={s.card}>
							<Card.Title as={'h2'}>Данные о бизнесе</Card.Title>
							<Form>
								<ScrollToError />
								{!user?.email && (
									<InputGroup hasValidation>
										<Form.Label>Личный email</Form.Label>
										<Form.Control
											size="lg"
											type={'text'}
											value={values.email}
											name={'email'}
											onChange={(e) => {
												setFieldError(
													'email',
													undefined
												)
												setFieldValue(
													'email',
													e.target.value
												)
											}}
											isInvalid={!!errors.email}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.email}
										</Form.Control.Feedback>
									</InputGroup>
								)}
								{companiesList?.length !== 0 &&
									!isCompaniesLoading && (
										<>
											<div
												style={{ marginBottom: '16px' }}
											>
												Укажите организацию, для которой
												вы хотите создать рекламную
												кампанию
											</div>
											<div
												className={
													'd-flex justify-content-center w-100'
												}
												style={{ marginBottom: '16px' }}
											>
												<ButtonGroup
													className={'w-100'}
													vertical={isMobile}
												>
													<Button
														variant={
															isPreset
																? 'primary'
																: 'secondary'
														}
														onClick={() => {
															setPresetCompany(
																companiesList?.[0] as CompanyType
															)
															setIsPreset(true)
														}}
														size={
															isMobile
																? undefined
																: 'lg'
														}
													>
														Выбрать из текущих
													</Button>
													<Button
														variant={
															!isPreset
																? 'primary'
																: 'secondary'
														}
														onClick={() => {
															setIsPreset(false)
															setPresetCompany(
																null
															)
															resetForm()
														}}
														size={
															isMobile
																? undefined
																: 'lg'
														}
													>
														Создать новую
														организацию
													</Button>
												</ButtonGroup>
											</div>
										</>
									)}
								<CommonFields
									setFieldValue={setFieldValue}
									values={values}
									setFieldError={setFieldError}
									errors={errors}
									companiesList={companiesList || []}
									setPresetCompany={setPresetCompany}
									presetCompany={presetCompany}
									isPreset={isPreset}
									industryTypes={industryTypes || []}
								/>
								<InputGroup className={'flex-column mb-0'}>
									<Form.Label>
										Тип бизнеса
										<FormLabelTooltip
											text={TOOLTIPS.STEP_0.INN}
										/>
									</Form.Label>
								</InputGroup>
								{values && (
									<ButtonGroupBooleanSwitch
										elements={['Физ. лицо', 'ООО или ИП']}
										setFieldValue={setFieldValue}
										initialValue={values.is_selfbusy}
										reverse={true}
										name={'is_selfbusy'}
										readOnly={isPreset}
										onChangeCB={() => {
											setFieldValue('inn', '')
											setFieldValue('legal_name', '')
											setFieldValue(
												'legal_short_name',
												''
											)
											setFieldValue('actual_location', '')
										}}
									/>
								)}

								{values.is_selfbusy && (
									<SelfBusy
										setFieldValue={setFieldValue}
										values={values}
										setFieldError={setFieldError}
										errors={errors}
										companiesList={companiesList || []}
										setPresetCompany={setPresetCompany}
										presetCompany={presetCompany}
										isPreset={isPreset}
										industryTypes={industryTypes || []}
										cn={cn}
									/>
								)}
								{!values.is_selfbusy && (
									<Company
										setFieldValue={setFieldValue}
										values={values}
										setFieldError={setFieldError}
										errors={errors}
										companiesList={companiesList || []}
										setPresetCompany={setPresetCompany}
										presetCompany={presetCompany}
										isPreset={isPreset}
										industryTypes={industryTypes || []}
										cn={cn}
									/>
								)}
							</Form>
						</Card>

						{values.is_selfbusy && isSelfBusyLimitReached && (
							<SelfBusyLimitCard />
						)}

						<div className={s.buttonsContainer}>
							<Button
								variant={'secondary'}
								onClick={onBack}
								style={{
									marginRight: isMobile ? '0.5rem' : '',
								}}
							>
								Назад
							</Button>
							<Button
								onClick={() => handleSubmit()}
								variant={'primary'}
								disabled={
									loading ||
									(values.is_selfbusy &&
										isSelfBusyLimitReached)
								}
								style={{
									width: isMobile ? '100%' : '',
								}}
							>
								{loading ? (
									<Spinner
										animation="border"
										variant={'light'}
										size="sm"
									/>
								) : (
									'Отправить на модерацию'
								)}
							</Button>
						</div>
					</div>
				)
			}}
		</Formik>
	)
}

export default BusinessDataForm
