import React from 'react'
import { InputGroup } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import { Form, Button } from 'react-bootstrap'
import { Formik, Form as FormikForm, FormikHelpers } from 'formik'
import { phoneValidationSchema } from '../../../model/schemas'
import { PhoneInputFormData } from '../../../model/types'
import s from './resetStages.module.scss'
import { SubmitButton } from '../../common/SubmitButton'

type Props = {
	onSubmit: (
		values: PhoneInputFormData,
		formikHelpers: FormikHelpers<PhoneInputFormData>
	) => Promise<void>
}
export const PhoneInput = ({ onSubmit }: Props) => {
	const savedPhoneNumber = sessionStorage.getItem('passRecoveryPhone')

	return (
		<Formik<PhoneInputFormData>
			initialValues={{
				phone_number: savedPhoneNumber || '',
			}}
			validationSchema={phoneValidationSchema}
			onSubmit={onSubmit}
		>
			{({ errors, touched, values, setFieldValue, isSubmitting }) => (
				<FormikForm>
					<div className={s.formContent}>
						<div className={s.inputs}>
							<Form.Group>
								<Form.Label>Телефон</Form.Label>
								<InputGroup hasValidation className="m-0">
									<InputMask
										autoComplete="off"
										mask={'+7 (999) 999-99-99'}
										value={values.phone_number}
										onChange={(e) => {
											setFieldValue(
												'phone_number',
												e.target.value
											)
										}}
										disabled={isSubmitting}
									>
										{(inputProps) => (
											<Form.Control
												name="customPhoneField"
												className={s.autofillNone}
												size="lg"
												type={'text'}
												placeholder={
													'+7 (999) 999-99-99'
												}
												style={{
													borderRadius: '16px',
													boxShadow: 'none',
												}}
												isInvalid={
													touched.phone_number &&
													!!errors.phone_number
												}
												{...inputProps}
											/>
										)}
									</InputMask>

									<Form.Control.Feedback type="invalid">
										{errors.phone_number}
									</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
						</div>

						<SubmitButton
							disabled={isSubmitting}
							text="Продолжить"
						/>
					</div>
				</FormikForm>
			)}
		</Formik>
	)
}
