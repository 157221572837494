import React from 'react'
import { Card } from 'react-bootstrap'
import s from './advTypeSummaryInfo.module.scss'
import useCampaignData from 'src/stores/campaignData'
import { useMemo } from 'react'
import IntlFormatter from 'src/utils/intl-money-formatter'

type Props = {
	advType: 'indoor' | 'outdoor' | 'internet'
}
export const AdvTypeSummaryInfo = ({ advType }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	return (
		<Card className={s.advTypeSummaryInfo}>
			<div className="d-flex justify-content-between">
				<h5>Стоимость всего:</h5>
				<h5>{IntlFormatter.format(campaign.budget?.[advType])}</h5>
			</div>
		</Card>
	)
}
