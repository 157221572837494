import React, { useEffect, useMemo, useState } from 'react'
import InternetUpload from './Internet/InternetUpload'
import BookingUpload from './components/booking/BookingUpload'
import ReactDOM from 'react-dom'
import s from './DataUpload.module.scss'
import useResponsive from '../../../../utils/useResponsive'
import useCampaignData from '../../../../stores/campaignData'
import { prettyThousands } from '../../../_provider/Mediaformats/MediaInfo'
import TVUpload from './Tv/TVUpload'
import UploadStatus from './UploadStatus'
import { useHistory } from 'react-router-dom'
import { PublicTransportUpload } from './components'
import { useTechnicalRequirementsStore } from 'src/stores/technical-requirements-store'
import { useCampaignInfoStore } from '../../campaign-info/store/campaignInfo.store'
import { OutDoorUpload } from './components/booking/OutDoorUpload'
import { IndoorUpload } from './components/booking/InDoorUpload'
import { MediaPlanWatcher } from './components/media-plan-watcher/MediaPlanWatcher'

const DataUploadSteps = [
	{ label: 'Наружная реклама', Component: OutDoorUpload },
	{ label: 'Внутренняя реклама', Component: IndoorUpload },
	{ label: 'Общественный транспорт', Component: PublicTransportUpload },
	{ label: 'Интернет-реклама', Component: InternetUpload },
	{ label: 'ТВ-реклама', Component: TVUpload },
]
const DataUpload = ({ cn, onBack, setStep: setGlobalStep }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const campaignId = campaign.id

	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)
	const resetAdvFormats = useTechnicalRequirementsStore(
		(state) => state.resetAdvFormats
	)

	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const resetVinstantCreative = useCampaignInfoStore(
		(state) => state.resetVinstantCreative
	)

	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()

	const isOutDoor = useMemo<boolean>(
		() =>
			campaign?.outdoor_adv_is_selected ||
			campaign.district_adv_is_selected,
		[campaign]
	)

	const isInDoor = useMemo<boolean>(
		() =>
			campaign?.indoor_adv_is_selected &&
			campaign?.indoor_bookings.length !== 0,
		[campaign]
	)

	const isPublicTransport = useMemo(
		() => !!campaign?.public_transport?.is_selected,
		[campaign]
	)
	const isInternet = useMemo<boolean>(
		() =>
			campaign.internet_yandex.is_selected ||
			campaign.internet_vk.is_selected,
		[campaign]
	)
	const isTV = useMemo(
		() =>
			campaign?.teletarget?.is_selected ||
			campaign?.adstream?.is_selected,
		[campaign]
	)

	const isStepper = useMemo(
		() =>
			(isOutDoor &&
				(isTV || isInternet || isInDoor || isPublicTransport)) ||
			(isInDoor &&
				(isTV || isInternet || isOutDoor || isPublicTransport)) ||
			(isPublicTransport &&
				(isOutDoor || isInternet || isInDoor || isTV)) ||
			(isInternet &&
				(isOutDoor || isTV || isInDoor || isPublicTransport)) ||
			(isTV &&
				(isOutDoor || isInternet || isInDoor || isPublicTransport)),
		[isOutDoor, isInternet, isTV, isInDoor]
	)

	const DataUploadStepsOnlySelected = useMemo(() => {
		return DataUploadSteps.filter((step: any) => {
			if (step.label === 'Наружная реклама')
				if (isOutDoor) return { ...step }
			if (step.label === 'Внутренняя реклама')
				if (isInDoor) return { ...step }
			if (step.label === 'Общественный транспорт')
				if (isPublicTransport) return { ...step }
			if (step.label === 'Интернет-реклама')
				if (isInternet) return { ...step }
			if (step.label === 'ТВ-реклама') if (isTV) return { ...step }
			return null
		})
	}, [isStepper]) // eslint-disable-line react-hooks/exhaustive-deps

	const [step, setStep] = useState(() => {
		const savedStep = localStorage.getItem(`stepo_${campaign.id}`)
		return savedStep !== null ? Number(savedStep) : 0
	})

	const handleSetNextStep = () => {
		if (isStepper) {
			//check last local step
			if (step === DataUploadStepsOnlySelected.length - 1) {
				setGlobalStep((step) => ++step)
			} else {
				setStep((step) => ++step)
			}
		} else {
			setGlobalStep((step) => ++step)
		}
	}

	useEffect(() => {
		localStorage.setItem(`stepo_${campaign.id}`, step.toString())
	}, [step, campaign.id])

	useEffect(() => {
		const handleBeforeUnload = () => {
			localStorage.removeItem(`stepo_${campaign.id}`)
		}

		const handleRouteChange = () => {
			localStorage.removeItem(`stepo_${campaign.id}`)
		}

		window.addEventListener('beforeunload', handleBeforeUnload)
		history.listen(handleRouteChange)

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
			history.listen(handleRouteChange)
		}
	}, [campaign.id, history])

	const fetchData = async () => {
		await fetchTechnicalRequirements(campaign)
		await fetchVinstantCreative(campaign.id)
	}
	useEffect(() => {
		if (campaignId) {
			fetchData()
		}

		return () => {
			resetAdvFormats()
			resetVinstantCreative()
		}
	}, [campaignId])

	const Component = useMemo(
		() => DataUploadStepsOnlySelected[step]?.Component,
		[step, DataUploadStepsOnlySelected]
	)

	return (
		<div className={s.container}>
			<MediaPlanWatcher />
			{isStepper && (
				<>
					<RenderSubMenu
						step={step}
						setStep={setStep}
						DataUploadStepsOnlySelected={
							DataUploadStepsOnlySelected
						}
					/>

					<UploadStatus
						availableSteps={DataUploadStepsOnlySelected}
						currentStep={step}
						s={s}
						setStep={setStep}
					/>
					{Component && (
						<Component
							isLoading={isLoading}
							cn={cn}
							onBack={onBack}
							onNext={handleSetNextStep}
							onStepBack={() => setStep((p) => p - 1)}
							isStepper={isStepper}
							setGlobalStep={setGlobalStep}
							isLastStep={
								step === DataUploadStepsOnlySelected.length - 1
							}
							step={step}
							totalSteps={DataUploadStepsOnlySelected.length - 1}
						/>
					)}
				</>
			)}
			{isInternet && !isStepper && (
				<InternetUpload
					isLoading={isLoading}
					cn={cn}
					onStepBack={() => setStep(0)}
					onBack={onBack}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					step={step}
					totalSteps={DataUploadStepsOnlySelected.length - 1}
					onNext={handleSetNextStep}
				/>
			)}
			{isPublicTransport && !isStepper && (
				<PublicTransportUpload
					isLoading={isLoading}
					cn={cn}
					onStepBack={() => setStep(0)}
					onBack={onBack}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					step={step}
					totalSteps={DataUploadStepsOnlySelected.length - 1}
					onNext={handleSetNextStep}
				/>
			)}
			{isInDoor && !isStepper && (
				<IndoorUpload
					isLoading={isLoading}
					cn={cn}
					onBack={onBack}
					onNext={handleSetNextStep}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					onStepBack={() => setStep(0)}
					step={step}
					totalSteps={DataUploadStepsOnlySelected.length - 1}
					door_type={'indoor'}
				/>
			)}
			{isOutDoor && !isStepper && (
				<OutDoorUpload
					isLoading={isLoading}
					cn={cn}
					onBack={onBack}
					onNext={handleSetNextStep}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					onStepBack={() => setStep(0)}
					step={step}
					totalSteps={DataUploadStepsOnlySelected.length - 1}
					door_type={'outdoor'}
				/>
			)}
			{isTV && !isStepper && (
				<TVUpload
					isLoading={isLoading}
					cn={cn}
					onBack={onBack}
					onNext={handleSetNextStep}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					onStepBack={() => setStep(0)}
					step={step}
					totalSteps={DataUploadStepsOnlySelected.length - 1}
				/>
			)}
		</div>
	)
}

const RenderSubMenu = ({ step, setStep, DataUploadStepsOnlySelected }) => {
	const { isMobile, isTablet } = useResponsive()
	const [, updateState] = React.useState<any>()
	const forceUpdate = React.useCallback(() => updateState({}), [])
	useEffect(() => {
		forceUpdate()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	const SubMenuDesktop = () => (
		<div className={s.submenu}>
			{DataUploadStepsOnlySelected.map(({ label }, i) => (
				<div
					className={s.submenuItem}
					data-active={i <= step ? 'active' : ''}
					key={label}
					onClick={() => (i <= step ? setStep(i) : null)}
					style={{ cursor: i <= step ? 'pointer' : 'default' }}
				>
					<div className={s.circle} />
					<div className={s.label}>{label}</div>
				</div>
			))}
		</div>
	)
	const SubMenuTablet = () => (
		<div className={s.submenuTablet}>
			{/* {DataUploadStepsOnlySelected.map(({ label }, i) => (
				<div
					className={s.submenuItemTablet}
					onClick={() => (i <= step ? setStep(i) : null)}
					style={{ cursor: i <= step ? 'pointer' : 'default' }}
					data-active={i <= step ? 'active' : ''}
					key={label}
				>
					<div className={s.circle} />
					<div className={s.label}>{label}</div>
				</div>
			))} */}
		</div>
	)
	const SubMenuMobile = () => (
		<div className={s.submenuMobile}>
			{DataUploadStepsOnlySelected.map(({ label }, i) => (
				<div
					className={s.submenuItemMobile}
					onClick={() => (i <= step ? setStep(i) : null)}
					style={{ cursor: i <= step ? 'pointer' : 'default' }}
					data-active={i <= step ? 'active' : ''}
					key={label}
				>
					<div className={s.circle} />
				</div>
			))}
		</div>
	)
	return ReactDOM.createPortal(
		<>
			{/*TODO Придумать другой вариант отображения для мобилки, сейчас не влезает все* */}
			{/*{isMobile && <SubMenuMobile />}*/}
			{isTablet && !isMobile && <SubMenuTablet />}
			{!isMobile && !isTablet && <SubMenuDesktop />}
		</>,
		// @ts-ignore:next-line
		document.querySelector('.stepper-block-step-3')
	)
}

export default DataUpload

export const minMax = (min, max, prefix) => {
	if (min === max) return `${prettyThousands(min)} ${prefix}`
	if (min === null && max) return `${prettyThousands(max)} ${prefix}`
	if (max === null && min) return `${prettyThousands(min)} ${prefix}`
	return `${prettyThousands(min)} - ${prettyThousands(max)} ${prefix}`
}
