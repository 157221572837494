import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'

type Props = {
	open: boolean
	onClose: () => void
}
export const HelpPageModal = ({ onClose, open }: Props) => {
	const [loading, setLoading] = useState(true) // Состояние для лоадера

	return (
		<Modal show={open} onHide={onClose} fullscreen animation={true}>
			<Modal.Header closeButton>
				<Modal.Title as="h5" className="ms-2">
					Справка
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				className="p-0"
				style={{ overflow: 'hidden', position: 'relative' }}
			>
				{/* Лоадер (показывается, пока iframe не загрузится) */}
				{loading && (
					<div
						className="w-100 h-100 d-flex justify-content-center align-items-center"
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							background: 'rgba(255, 255, 255, 0.9)',
						}}
					>
						<Spinner animation="border" variant="primary" />
					</div>
				)}

				{/* iframe с обработчиком onLoad */}
				<iframe
					className="w-100 h-100"
					src="https://admarket.ru/help/registration"
					onLoad={() => setLoading(false)} // Скрываем лоадер при загрузке
				></iframe>
			</Modal.Body>
		</Modal>
	)
}
