import React, {
	Children,
	cloneElement,
	useEffect,
	useRef,
	useState,
} from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { ReactComponent as MoreIcon } from 'src/assets/images/more-vert.svg'
import s from './garantidItem.module.scss'
import useResponsive from 'src/utils/useResponsive'
import SmallBottomSheet from 'src/components/UtilityComponents/SmallBottomSheet'

const CustomToggle = React.forwardRef(
	(
		{
			children,
			onClick,
			wrappedClick,
		}: {
			children: React.ReactChildren
			onClick: (
				e: React.MouseEvent<HTMLButtonElement, MouseEvent>
			) => void
			wrappedClick?: (
				e: React.MouseEvent<HTMLButtonElement, MouseEvent>
			) => void
		},
		ref: React.Ref<HTMLButtonElement>
	) => (
		<Button
			ref={ref}
			className={s.garantidItemMenuButton}
			onClick={(e) => {
				e.preventDefault()

				if (wrappedClick && typeof wrappedClick === 'function') {
					wrappedClick(e)
				} else {
					onClick(e)
				}
			}}
			variant="text"
		>
			{children}
		</Button>
	)
)
CustomToggle.displayName = 'CustomToggle'

const CustomMenu = React.forwardRef(
	(
		{
			children,
			style,
			className,
			'aria-labelledby': labeledBy,
			wrappedClick,
		}: {
			children: React.ReactChildren
			style: React.CSSProperties
			className: string
			'aria-labelledby': string
			wrappedClick?: (
				e: React.MouseEvent<HTMLDivElement, MouseEvent>
			) => void
		},
		ref: React.Ref<HTMLDivElement>
	) => {
		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
				onClick={(e) => {
					if (wrappedClick && typeof wrappedClick === 'function') {
						wrappedClick(e)
					}
				}}
			>
				{children}
			</div>
		)
	}
)

CustomMenu.displayName = 'CustomMenu'

type Props = {
	children: React.ReactNode
	mobileViewClassName?: string
	isAnyAction?: boolean
}
export const GarantidItemMenu = ({
	children,
	mobileViewClassName,
	isAnyAction = true,
}: Props) => {
	const { isMobile } = useResponsive()
	const childrenRef = useRef<HTMLDivElement | null>(null)
	const [height, setHeight] = useState(0)

	const [open, setOpen] = useState(false)

	const handleMobileToggleClick = isMobile
		? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				setOpen(!open)
		  }
		: undefined

	const handleCloseMobileMenu = isMobile
		? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
				setOpen(false)
		  }
		: undefined
	useEffect(() => {
		if (childrenRef.current) {
			setHeight(childrenRef.current.clientHeight)
		}
	}, [open, children])

	const enhancedChildren = Children.map(children, (child) => {
		//потомок первого уровня
		if (React.isValidElement(child)) {
			if (
				//наличие свойства для автозакрытия и onClick обработчика
				child?.props?.mobileAutoClose &&
				typeof child.props?.onClick === 'function'
			) {
				return cloneElement(child, {
					...child.props,
					//вызов onClick обработчика и доп.действие
					onClick: () => {
						child.props.onClick()
						setOpen(false)
					},
				})
			} else {
				return cloneElement(child)
			}
		}
		return child
	})
	return (
		<Dropdown drop={'up'} align={'end'}>
			{isAnyAction && (
				<Dropdown.Toggle
					as={CustomToggle}
					id="dropdown-custom-components"
					wrappedClick={handleMobileToggleClick}
				>
					<MoreIcon />
				</Dropdown.Toggle>
			)}

			<Dropdown.Menu
				as={CustomMenu}
				className={s.garantidItemMenu}
				wrappedClick={handleCloseMobileMenu}
			>
				{children}
			</Dropdown.Menu>

			{open && (
				<SmallBottomSheet
					show={open}
					onHide={() => setOpen(false)}
					height={`${height + 25}px`}
					touchHeight={'15px'}
					styles={{ paddingTop: '25px' }}
				>
					<div ref={childrenRef} className={mobileViewClassName}>
						{enhancedChildren}
					</div>
				</SmallBottomSheet>
			)}
		</Dropdown>
	)
}
