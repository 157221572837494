import { defaultPlacementImage } from '../constants/defaultPlacementImage'
import { AdvItemState, AdvMediaType } from '../types'
import {
	AdvFormatBookingItem,
	MediaFormatViewModel,
	GarantidItem,
	PlacementViewModel,
} from '../types/viewModel.types'

export const outdoorMapper = (mediaTypes: AdvMediaType[]) => {
	const allMediaTypesWithOutdoors = mediaTypes.filter((mediaType) =>
		mediaType.placements.find((placement) => placement.outdoors.length > 0)
	)

	if (allMediaTypesWithOutdoors.length === 0) {
		return []
	}
	const outdoors = allMediaTypesWithOutdoors.map(
		(mediaType): MediaFormatViewModel<GarantidItem, PlacementViewModel> => {
			const placementsWithOutdoor = mediaType.placements.filter(
				(placement) => placement.outdoors.length > 0
			)

			let approvedCount = 0
			let rejectedCount = 0

			const outdoorBookings = placementsWithOutdoor.map((placement) => {
				const item: AdvFormatBookingItem<
					GarantidItem,
					PlacementViewModel
				> = {
					item: {
						adjustment_msg: placement.outdoors[0].adjustment_msg,
						booking_budget:
							placement.outdoors[0].booking_budget || '0',
						id: placement.outdoors[0].id,
						is_docs_required:
							placement.outdoors[0].is_docs_required,
						media_creative: placement.outdoors[0].media_creative,
						reject_msg: placement.outdoors[0].reject_msg,
						placement_showing_count: {
							showing_count_per_day:
								placement.outdoors[0].placement_showing_count
									.showing_count_per_day || 0,
							video_duration:
								placement.outdoors[0].placement_showing_count
									.video_duration || 0,
						},
						state: placement.outdoors[0].state,
					},
					placement: {
						id: placement.id,
						image: placement.image || defaultPlacementImage,
						name: placement.name,
						address: placement.address,
					},
				}

				if (item.item.state === AdvItemState.Confirm) {
					approvedCount += 1
				}
				if (
					item.item.state === AdvItemState.Reject ||
					item.item.state === AdvItemState.Adjustment
				) {
					rejectedCount += 1
				}

				return item
			})

			return {
				id: mediaType.id,
				total_format_budget: outdoorBookings
					.reduce((booking, current) => {
						if (
							current.item.state === AdvItemState.Canceled ||
							current.item.state === AdvItemState.Reject
						) {
							return booking
						}
						return booking + Number(current.item.booking_budget)
					}, 0)
					.toString(),
				counts: {
					total: outdoorBookings.length,
					approved: approvedCount,
					rejected: rejectedCount,
				},
				booking_item: outdoorBookings,
				media_type_name: mediaType.name,
				media_requirements: {
					age_restriction_requirements:
						mediaType.age_restriction_requirements,
					color_model: mediaType.color_model,
					codec: mediaType.codec,
					disclaimer_requirements: mediaType.disclaimer_requirements,
					duration: mediaType.duration,
					image_formats: mediaType.image_formats,
					image_max_size: mediaType.image_max_size,
					max_bitrate: mediaType.max_bitrate,
					max_dpi: mediaType.max_dpi,
					max_fps: mediaType.max_fps,
					max_height: mediaType.max_height,
					max_width: mediaType.max_width,
					min_bitrate: mediaType.min_bitrate,
					min_dpi: mediaType.min_dpi,
					min_fps: mediaType.min_fps,
					min_height: mediaType.min_height,
					min_width: mediaType.min_width,
					is_image: mediaType.is_image,
					is_video: mediaType.is_video,
					other_requirements: mediaType.other_requirements,
					video_formats: mediaType.video_formats,
					video_max_size: mediaType.video_max_size,
					with_audio_track: mediaType.with_audio_track,
					additional_info: mediaType.additional_info,
					aspect_ratio: mediaType.aspect_ratio,
					external_link: mediaType.external_link,
				},
			}
		}
	)

	return outdoors
}
