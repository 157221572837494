import {
	BookingState,
	CampaignInfoTypeBookingsSeparation,
	InternetState,
} from 'src/stores/ADMarketTypes.types'

/**
 * Проверяет, находится ли кампания во внутренней модерации.
 *
 * @param {CampaignInfoTypeBookingsSeparation} campaign - Данные о кампании.
 * @returns {boolean} Возвращает `true`, если хотя бы один из элементов находится во внутренней модерации, иначе `false`.
 */
export const checkIsInternalModeration = (
	campaign: CampaignInfoTypeBookingsSeparation
): boolean => {
	return (
		campaign.booking.some(
			(booking) => booking.state === BookingState.Inner_Moderation
		) ||
		campaign.districtbookings.some(
			(district) => district.state === BookingState.Inner_Moderation
		) ||
		[
			campaign.internet_vk.state,
			campaign.internet_yandex.state,
			campaign.public_transport?.state,
			campaign.adstream.state,
		].some((state) => state === InternetState.Inner_Moderation)
	)
}
