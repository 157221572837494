import React, { useState } from 'react'
import { Badge, Button, ButtonGroup, Form } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import s from './appLinks.module.scss'
import cn from 'classnames'
import { BottomLink } from './BottomLink'
import { ReactComponent as QuestionRing } from '../../../../../assets/images/question-ring.svg'
import { SidebarMenuItemType } from '../../ClientSidebar'
import useUserData from 'src/stores/userData'
import { DemoLink } from './DemoLink'
import { HelpPageModal } from './HelpPageModal'

type AppLinksProps = {
	onCreateCampaign: () => void
	isDesktop: boolean
	active: string
	setActive: (active: string) => void
	linkClick: (path: string) => void
	forceOpened: boolean
	setForceOpened: (forceOpened: boolean) => void
	client_menu_with_counts: Array<SidebarMenuItemType>
}

export const AppLinks = ({
	onCreateCampaign,
	isDesktop,
	active,
	setActive,
	forceOpened,
	linkClick,
	setForceOpened,
	client_menu_with_counts,
}: AppLinksProps) => {
	const [user, userInterface] = useUserData()
	const history = useHistory()
	const [modalType, setModalType] = useState<'helpPage' | null>(null)

	const isModerState = userInterface.getIsModerState()

	const handleSwitchModerState = (value: boolean) => {
		if (value) {
			history.push('/moder')
		} else {
			history.push('/campaigns')
		}

		userInterface.setIsModerState(value)
	}

	return (
		<div className={cn(s.appLinksContainer)} id={'app-links-container'}>
			<div id={'top-sidebar-links'}>
				{!isModerState && (
					<div
						style={{
							margin: '0 16px 24px 16px',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Button
							style={{ width: '207px' }}
							variant={'primary'}
							onClick={onCreateCampaign}
						>
							Создать кампанию
						</Button>
					</div>
				)}

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<div className={cn(s.topLinks)}>
						{client_menu_with_counts.map((menuItem) => (
							<a
								onClick={(e) => {
									e.preventDefault()
									if (!isDesktop) setForceOpened(false)
									linkClick(menuItem.path)
								}}
								href={'/' + menuItem.key}
								className={cn(s.link, {
									[s.active]: active === menuItem.key,
								})}
								key={menuItem.key}
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									<menuItem.icon.item
										style={{
											width: '20px',
											height: '20px',
										}}
									/>
									<div style={{ marginLeft: '14px' }}>
										{menuItem.title}
									</div>
								</div>

								{menuItem.withCount && (
									<Badge>{menuItem.count}</Badge>
								)}
							</a>
						))}
					</div>
				</div>
			</div>

			<div className={cn(s.bottomLinks)} id={'bottom-sidebar-links'}>
				{user?.is_moder && (
					<Form>
						<Form.Check // prettier-ignore
							type="switch"
							id="custom-switch"
							label="Менеджер"
							checked={isModerState}
							onChange={(e) =>
								handleSwitchModerState(e.target.checked)
							}
							style={{
								fontSize: '14px',
								color: '#CED4DA',
								opacity: isModerState ? 10 : 0.6,
								marginLeft: '16px',
							}}
						/>
					</Form>
				)}
				<DemoLink />

				<Button
					variant="text"
					onClick={() => setModalType('helpPage')}
					className={s.modalActivator}
				>
					<QuestionRing aria-label="Cправка" />
					<div>
						<span>Справка</span>
					</div>
				</Button>

				{modalType === 'helpPage' && (
					<HelpPageModal
						onClose={() => setModalType(null)}
						open={modalType === 'helpPage'}
					/>
				)}
			</div>
		</div>
	)
}
