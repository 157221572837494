import React, { useMemo } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import s from '../garantid-card/garantidMediaCard.module.scss'
import {
	AdvItemState,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import { ReactComponent as DistirictsIcon } from 'src/assets/images/radius.svg'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { DistrictBookingItemCard } from './districtBooking-item-card/DistrictBookingItemCard'

export const DistrictBookingCard = () => {
	const districtBookings = useTechnicalRequirementsStore(
		(state) => state['district']
	)

	const totalAdvBudget = useMemo(() => {
		return districtBookings.reduce((acc, format) => {
			if (
				format.state === AdvItemState.Reject ||
				format.state === AdvItemState.Canceled
			) {
				return acc
			}

			return acc + parseFloat(format.amount)
		}, 0)
	}, [districtBookings])

	return (
		<Card className={s.garantidCard}>
			<DistrictBookingMediaCardHeader budget={totalAdvBudget} />
			<Card.Body className={s.cardsContainer}>
				{districtBookings.map((districtBooking) => (
					<DistrictBookingItemCard
						key={districtBooking.id}
						districtBooking={districtBooking}
					/>
				))}
			</Card.Body>
		</Card>
	)
}

type GarantidMediaCardHeaderProps = {
	budget: number
}
export const DistrictBookingMediaCardHeader = ({
	budget,
}: GarantidMediaCardHeaderProps) => {
	const budgetValue = IntlFormatter.format(budget)
	const isLoading = useTechnicalRequirementsStore((state) => state.isLoading)

	return (
		<Card.Header className={s.garantidMediaCardHeader}>
			<div className={s.garantidMediaCardHeader_title}>
				{isLoading ? (
					<Spinner
						animation="border"
						size="sm"
						style={{ color: '#007BFF' }}
					/>
				) : (
					<DistirictsIcon />
				)}

				<h5>Максимальный охват</h5>
			</div>

			<h5>{budgetValue}</h5>
		</Card.Header>
	)
}
